.fade-in {
	animation: fadeIn 1s;
}

.scale-out {
	animation: scaleOut .5s;
}

@keyframes scaleOut {
	0% {
		transform: scaleX(0);
	}
	100% {
		transform: scaleX(1);
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
