@tailwind base;
@tailwind components;
@tailwind utilities;


.anim-fade-in {
    animation: fadeIn 1s;
}

.anim-scale-center {
    animation: scaleX .5s;
}

.anim-scale-down {
    transform-origin: top;
    animation: scaleY .5s;
}

@keyframes scaleY {
    0% {
        transform: scaleY(0);
    }

    100% {
        transform: scaleY(1);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.table-zebra thead tr th {
    @apply bg-neutral text-neutral-content
}



/* poppins-200 - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    src: local('poppins-v19-latin-ext_latin-200'),
        url('./fonts/poppins/poppins-v19-latin-ext_latin-200.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fonts/poppins/poppins-v19-latin-ext_latin-200.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* poppins-200italic - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 200;
    src: local('poppins-v19-latin-ext_latin-200italic'),
        url('./fonts/poppins/poppins-v19-latin-ext_latin-200italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fonts/poppins/poppins-v19-latin-ext_latin-200italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* poppins-300 - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: local('poppins-v19-latin-ext_latin-300'),
        url('./fonts/poppins/poppins-v19-latin-ext_latin-300.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fonts/poppins/poppins-v19-latin-ext_latin-300.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* poppins-300italic - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 300;
    src: local('poppins-v19-latin-ext_latin-300italic'),
        url('./fonts/poppins/poppins-v19-latin-ext_latin-300italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fonts/poppins/poppins-v19-latin-ext_latin-300italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* poppins-regular - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: local('poppins-v19-latin-ext_latin-regular'),
        url('./fonts/poppins/poppins-v19-latin-ext_latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fonts/poppins/poppins-v19-latin-ext_latin-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* poppins-italic - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    src: local('poppins-v19-latin-ext_latin-italic'),
        url('./fonts/poppins/poppins-v19-latin-ext_latin-italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fonts/poppins/poppins-v19-latin-ext_latin-italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* poppins-500 - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: local('poppins-v19-latin-ext_latin-500'),
        url('./fonts/poppins/poppins-v19-latin-ext_latin-500.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fonts/poppins/poppins-v19-latin-ext_latin-500.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* poppins-500italic - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    src: local('poppins-v19-latin-ext_latin-500italic'),
        url('./fonts/poppins/poppins-v19-latin-ext_latin-500italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fonts/poppins/poppins-v19-latin-ext_latin-500italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* poppins-600 - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: local('poppins-v19-latin-ext_latin-600'),
        url('./fonts/poppins/poppins-v19-latin-ext_latin-600.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fonts/poppins/poppins-v19-latin-ext_latin-600.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* poppins-700 - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: local('poppins-v19-latin-ext_latin-700'),
        url('./fonts/poppins/poppins-v19-latin-ext_latin-700.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fonts/poppins/poppins-v19-latin-ext_latin-700.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* poppins-600italic - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    src: local('poppins-v19-latin-ext_latin-600italic'),
        url('./fonts/poppins/poppins-v19-latin-ext_latin-600italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fonts/poppins/poppins-v19-latin-ext_latin-600italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* poppins-700italic - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 700;
    src: local('poppins-v19-latin-ext_latin-700italic'),
        url('./fonts/poppins/poppins-v19-latin-ext_latin-700italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fonts/poppins/poppins-v19-latin-ext_latin-700italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* poppins-800italic - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 800;
    src: local('poppins-v19-latin-ext_latin-800italic'),
        url('./fonts/poppins/poppins-v19-latin-ext_latin-800italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fonts/poppins/poppins-v19-latin-ext_latin-800italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* poppins-800 - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    src: local('poppins-v19-latin-ext_latin-800'),
        url('./fonts/poppins/poppins-v19-latin-ext_latin-800.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fonts/poppins/poppins-v19-latin-ext_latin-800.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}